import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Home.vue'),
    meta: {
      keepAlive: true
    }
  },
  {
    path: '/licence',
    name: 'licence',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/licence.vue')
  },
  {
    path: '/buyCarList',
    name: 'buyCarList',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/buyCarList.vue')
  }
]
//  mode: 'history',
const router = new VueRouter({
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return {
        x: 0,
        y: 0
      }
    }
  }

  // scrollBehavior(to,from,savedPosition) {
  //   if(savedPosition)
  // }
})

export default router
