import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import $ from 'jquery'
Vue.prototype.$jquery = $
Vue.use(ElementUI)
Vue.prototype.$http = axios
Vue.config.productionTip = false
const isProd = process.env.NODE_ENV === 'production'
console.log('雷神 --- 专注于经营新能源二手车的在线电商');
if (isProd) {
  !(function () {
    window['__bl'] || (window['__bl'] = {})
    window['__bl'].config = {
      pid:"ehd8rnx9mn@260c90821c17882",
      appType: 'web',
      imgUrl: 'https://arms-retcode.aliyuncs.com/r.png?',
      sendResource: true,
      enableLinkTrace: true,
      behavior: true
    }
    let dom = document.createElement('script')
    dom.src = 'https://retcode.alicdn.com/retcode/bl.js'
    dom.setAttribute('crossorigin', '')
    document.body.insertBefore(dom, document.body.firstChild)
  })()
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
